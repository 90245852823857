
$blue: #0071bc; //#0071bc
$blueHover:#0062A3;
$transparentBlue:rgba(0, 113, 88, 0.2);
$countBlue1:#1E87BF;
$countBlue1Hover:#19709F;
$transparentBlue1:rgba(30, 135, 191, 0.2);
$countBlue2:#4DAAC5;
$countBlue2Hover:#3C9CB9;
$transparentBlue2:rgba(77, 170, 197, 0.2);
$countBlue3:#7BCCCA;
$countBlue3Hover:#5DC0BF;
$transparentBlue3:rgba(123, 204, 202, 0.2);
$countBlue4:#87D1CF;
$countBlue4Hover:#6BC7C4;
$transparentBlue4:rgba(135, 209, 207, 0.2);
$background: #D6D6D6;
$lightBackground: #F5F5F5;
$lightBackgroundHover: #EBEBEB;
$darkBlue: #00568F;
$darkenBlueSubNav:#003D66;
$darkenBlue: #003152;
$lightBlue: #0A9DFF;
$grayBlue: #121F2B;
$red: #B11B25;
$darkRed: #8E151D;
$orange: #F55E00; //C44900
$darkOrange: #CC4E00;
$green: #3B7B28;
$darkGreen: #2D5C1E;

$gray: #333333;
$grayTrans: rgba(51, 51, 51, 0.1);
$white: #ffffff;
$lightBlueGray: #F2F6F8;
$darkenLightBlue: #BCD2DC;//D7E4EA
$wordsGray: #8998A9;
$wordsArticle: #566576;

$transparent: rgba(0, 0, 0, 0);
$verre: rgba(255, 255, 255, 0.1);

*{
  font-family: 'Ubuntu', sans-serif;
}
*, *::before, *::after {
  box-sizing: border-box;
}
*:focus{
  outline: none;
}
* a{
  text-decoration: none;
}
body{
  margin: 0;
}
#dashboard{
  margin: 0;
  background: $background;
}
.progressBar{
  height: 5px;
  //width: 100%;
  background: $darkenBlue;
}
.red{
  color: $red;
}
.red:hover{
  color: $darkRed;
}
.orange{
  color: $orange;
}
.orange:hover{
  color: $darkOrange;
}
.green{
  color: $green;
}
.green:hover{
  color: $darkGreen;
}
#connexion{
  form{
    width: 100%;
  }
}
.login-page{
  text-align: center;
  width: 100%;
  input{
    border: 1px solid $darkBlue;
    border-radius: 3px;
    margin: 10px;
    padding: 10px 20px;
  }
  button{
    padding: 10px 20px;
    border-radius: 3px;
    background: $darkBlue;
    color: $lightBackground;
  }
  button:hover{
    background: $darkenBlue;
    cursor: pointer;
  }
}
.headerDashboard {
  background: $grayBlue;
  color: $lightBackground;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  height: 70px;
  h2, h4, h5{
    margin: 0;
  }
  .deconnexion{
    display: flex;
    flex-direction: row;
  }
}

.bodyAdmin{
  display: flex;
  flex-direction: row;
  min-height: calc(100% - 70px);
}
.dashboardNav{
  background: $darkBlue;
  width: 20%;
}
.navPrincAdmin ul, .subNavPrincAdmin ul{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    display: flex;
    flex-direction: column;
    //position: relative;
    margin-bottom: 10px;
  }
  .linkDashboard {
    margin-bottom: 30px;
  }
  li:hover{
    cursor: pointer;
    background: $darkenBlue;
  }
  .linkHome:hover{
    padding-bottom: 0;
  }
  li:hover .subNavPrincAdmin{
    display: block;
    //background: $darkenBlue;
    //position: absolute;
    //right: 0;
    //top: -10px;
    margin-top: 10px;
  }
  li:hover .subNavPrincAdmin{
    li{
      margin-bottom: 0;
    }
    li:hover{
      background: $darkenBlueSubNav;
      //margin-left: -15px;
      //margin-right: -15px;
    }

  }
  li a{
    color: $lightBackground;
    padding: 10px 15px;
    width: 100%;
  }
}
.subNavPrincAdmin{
  display: none;
}


.dashboardMain{
  width: 80%;
}

.welcomeContainer, .homeAdminContainer{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.headerWelcome, .headerHomeAdmin{
  h2{
    text-align: center;
  }
}
.mainWelcome, .mainHomeAdmin{
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.mainHomeAdmin{
  margin-left: 50px;
  margin-right: 50px;
  flex-wrap: wrap;
}
.mainWelcome div, .mainHomeAdmin div{
  background: $lightBackground;
  border-radius: 5px;
  text-align: center;
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  h2{
    margin: 0;
  }
  h3{
    margin: 0;
    padding-top: 20px;
  }
}
.mainWelcome div:hover, .mainHomeAdmin div:hover{
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.16), 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
  background: $lightBackgroundHover;
}
.mainHomeAdmin .tuileArt:hover .count{
  background: $countBlue1Hover;
  color: $lightBackgroundHover;
}
.mainHomeAdmin .tuileBigPic:hover .count{
  background: $countBlue2Hover;
  color: $lightBackgroundHover;
}
.mainHomeAdmin .tuilePart:hover .count{
  background: $countBlue3Hover;
  color: $lightBackgroundHover;
}
.mainHomeAdmin .tuileComm:hover .count{
  background: $countBlue4Hover;
  color: $lightBackgroundHover;
}
.mainHomeAdmin .tuileAcc:hover .count{
  background: $blueHover;
  color: $lightBackgroundHover;
}

.mainWelcome div{
  width: 340px;
  height: 340px;
  margin: 4.1%;
}
.mainHomeAdmin div{
  margin: 20px;
  width: 270px;
  height: 270px;
  position: relative;
  transition: 0.4s;
}
.mainWelcome a, .mainHomeAdmin a{
  color:$gray;
}
.count{
  position: absolute;
  background: $blue;
  padding: 30px;
  border-radius: 3px;
  margin: 0;
  top:-15px;
  left: -15px;
  font-size: 20px;
  color: $lightBackground;
}
.tuileAcc a:hover{
  border-radius: 5px;
  background: $transparentBlue;
  font-size: 1.1rem;
  transition: 0.4s;
}
.tuileArt .count{
  background: $countBlue1;
}
.tuileArt a:hover{
  border-radius: 5px;
  background: $transparentBlue1;
  font-size: 1.1rem;
  transition: 0.4s;
}
.tuileBigPic .count{
  background: $countBlue2;
}
.tuileBigPic a:hover{
  border-radius: 5px;
  background: $transparentBlue2;
  font-size: 1.1rem;
  transition: 0.4s;
}
.tuilePart .count{
  background: $countBlue3;
}
.tuilePart a:hover{
  border-radius: 5px;
  background: $transparentBlue3;
  font-size: 1.1rem;
  transition: 0.4s;
}
.tuileComm .count{
  background: $countBlue4;
}
.tuileComm a:hover{
  border-radius: 5px;
  background: $transparentBlue4;
  font-size: 1.1rem;
  transition: 0.4s;
}
.crudContainer{
  display: flex;
  flex-direction: column;
  margin: 50px;
}
.sectionCreate{
  padding: 30px;
  background: $grayBlue;
  border-radius: 5px;
  width: 100%;
  color:$lightBackground;
}
.create{
  h4{
    margin: 0;
    margin-bottom: 40px;
  }
}
.formCreate{
  display: flex;
  flex-direction: column;
  input, textarea{
    padding: 10px 20px;
    border-radius: 3px;
    border: none;
    margin-bottom: 20px;
  }
  button{
    width: 15%;
    padding: 10px 20px;
    border-radius: 3px;
    border: none;
    align-self: flex-end;
    background-color: $blue;
    color: $background;
  }
  button:hover{
    cursor: pointer;
    background: $darkBlue;
  }
}
.formCreate > .mce-panel{
  margin-bottom: 20px;
}
.formImg{
  display: flex;
  flex-direction: row;
  .btnFileContainer{
    position: relative;
     //width: 225px;
  }
  .inputFileTrigger{
    display: block;
    padding: 20px;
    margin-right: 20px;
    margin-top: 50%;
    //background: #39D2B4;
    color: $lightBackground;
    border: 1px solid $lightBackground;
    border-radius: 100%;
    font-size: 2em;
    font-weight: bolder;
    transition: all .4s;
    text-align: center;
    cursor: pointer;
  }
  .inputFileTrigger:hover{
    color: $blue;
    border: 1px solid $blue;
  }
  .inputFile{
    position: absolute;
    top: 0;
    left: 0;
    //width: 225px;
    //padding: 14px 0;
    opacity: 0;
    cursor: pointer;
  }
}
.sectionReadUpdate{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.containerItems{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.itemContainer{
  width: 45%;
}
#aboutAdmin{
  width: 100%;
}
.itemContainer, .updateContainer{
  margin: 20px;
  background: $lightBackground;
  padding: 30px;
  border-radius: 5px;
}
.itemAffichage{
  display: flex;
  flex-direction: column;
  h4{
    margin-top: 0;
  }
}
.partenaires{
  background: $grayBlue;
}
.buttonContainer{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button{
    border: none;
    //border-radius: 3px;
    background: none;
    font-size: 40px;
    //color: $lightBackground;
    margin-left: 10px;
    padding: 10px 20px;
  }
  button:hover{
    cursor: pointer;
  }
}
.updateContainer{
  .paragraphe{
    height: 100px;
  }
  .inputFileTrigger{
    color: $blue;
    border: 1px solid $blue;
  }
  .inputFileTrigger:hover{
    color: $darkBlue;
    border: 1px solid $darkBlue;
  }
}
