/******************/
/***   GLOBAL   ***/
/******************/
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body{
  margin: 0;
}

#app{
  margin: 0;
}

$blue: #0071bc; //#0071bc
$gray: #333333;
$grayTrans: rgba(51, 51, 51, 0.1);
$white: #ffffff;
$lightBlueGray: #F2F6F8;
$darkenLightBlue: #BCD2DC;//D7E4EA
$wordsGray: #8998A9;
$wordsArticle: #566576;

$transparent: rgba(0, 0, 0, 0);
$verre: rgba(255, 255, 255, 0.1);

*{
  font-family: 'Ubuntu', sans-serif;
}
*, *::before, *::after {
  box-sizing: border-box;
}
*:focus{
  outline: none;
}

/******************/
/***   HEADER   ***/
/******************/

.headerContainer{
  //background: linear-gradient($gray, rgba(51, 51, 51, 0.1));
  top:0;
  position: fixed;
  padding-top: 50px;
  //padding-bottom: 30px;
  width: 100%;
  z-index: 2;
  transition: 0.5s;
}
.headerBackground{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px;
  background: $white;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
}
.headerContainer .title{
  width: 50%;
  align-self: center;
  margin-left: 50px;
  img{
    width: 100px;
    height: auto;
  }
  h1{
    display: none;
  }
}
.headerContainer .navigation {
  width: 50%;
  align-self: center;
  margin-right: 0px;
}
.borderBottom{
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, $gray, rgba(51, 51, 51, 0));
}
.headerContainer .navigationPrincipale ul{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  list-style: none;
  margin-right: 50px;
}
.headerContainer .navigationPrincipale li{
  padding: 5px 20px;
  border-bottom: 2px solid $transparent;
  cursor: pointer;
  a{
    text-decoration: none;
    color: $gray;
  }
}
.headerContainer .navigationPrincipale li:hover {
  border-bottom: 2px solid $gray;
}
.scroll{
  background: $white;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 10px 1.5em rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.scroll .title{
  img{
    width: 75px;
  }
}
.scroll .navigationPrincipale ul{
  margin-bottom: 10px;
}
.scroll .navigationPrincipale li{
  padding: 0 20px 5px;
  border-bottom: 1px solid $transparent;
}
.scroll .navigationPrincipale li a{
  color: $gray;
}
.scroll .navigationPrincipale li:hover {
  border-bottom: 1px solid $blue;
}
.scroll .borderBottom {
  background: linear-gradient(to left, $blue, rgba(255, 255, 255, 0));
}
/******************/
/***   FOOTER   ***/
/******************/

.footerContainer{
  background: $lightBlueGray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
}
.upFooter, .downFooter{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.upFooter{
  padding-bottom: 50px;
}
.downFooter {
  margin: 0px 50px;
  justify-content:center;
  border-top: 1px solid $white;
  font-weight: lighter;
  color: $gray;
  p, a{
    margin-top: 0;
    padding-top: 0;
    padding: 0 10px;
  }
  a{
    cursor: pointer;
    font-weight: lighter;
    text-decoration: none;
    color: $gray;
  }
  a:hover{
    text-decoration: underline;
  }
}
.footerContainer .title{
  width: 33%;
  align-self: center;
  margin-left: 100px;
  img{
    width: 130px;
    height: auto;
  }
  h2{
    display: none;
  }
}
.footerContainer .contactFooter{
  width: 33%;
  align-self: center;
  text-align: center;
  a{
    cursor: pointer;
    font-weight: lighter;
    text-decoration: none;
    color: $gray;
  }
  a:hover{
    text-decoration: underline;
  }
}
.footerContainer .navigation {
  width: 33%;
  align-self: center;
  margin-right: 100px;
}
.footerContainer .navigationPrincipale ul{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  list-style: none;
  margin-right: 50px;
}
.footerContainer .navigationPrincipale li{
  padding: 0 20px;
  a{
    cursor: pointer;
    font-weight: lighter;
    text-decoration: none;
    color: $gray;
  }
  a:hover{
    text-decoration: underline;
  }
}

/***********************/
/***                 ***/
/***    ALL PAGES    ***/
/***                 ***/
/***********************/

.tuileContainer{
  max-width: 1400px; //1138
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
#buttonToTop{
  padding: 10px;
  border: none;
  cursor: pointer;
  background: rgba(51, 51, 51, 0.3);
  color: $white;
  position: fixed;
  right: 10px;
  bottom: 10px;
  border: none;
  border-radius: 100%;
  z-index: 3;
  span{
    display: none;
  }
  img{
    width: 60px;
  }
}
.none{
  display: none;
}
.block{
  display: block !important;
}

/******************/
/***    HOME    ***/
/******************/

/*** Tuile accueil ***/
.bgAccueil{
  background-image: url("./img/backgroundAccueil.jpg"); //"./img/accueilBg.jpg"
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0.2;
  width: 100%;
  height: 100vh;
}
.TuileAccueil{
  position: relative;
  margin-bottom: 200px;
  background: black;
  /*video{
    max-width: 100%;
  }
  img{
    display: none;
  }*/
  .bannerBackground{
    position: absolute;
    top: 50vh;
    height: 182px;
    width: 80%;
    left: calc(20% - 1px);
    border-radius: 3px;
    border: 0.5px solid $white;
    border-right: 0px solid $white;
    background-color: $grayTrans;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  .banner{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50vh;
    width: 100%;
    color: $white;
    h2{
      margin-top: 24px;
      margin-left: 22%;
      font-size: 3rem;
      margin-bottom: 24px;
    }
    .bannerText{
      margin-left: 22%;
      display: flex;
      flex-direction: row;
    }
    p{
      padding-right: 20px;
      font-size: 1.5rem;
    }
    .borderBottom{
      align-self: flex-end;
      width: 78%;
      height: 3px;
      background: linear-gradient(to right, $white, rgba(51, 51, 51, 0));
    }
  }
}

#particlesJs {
  top:0;
  position: absolute;
  width: 100%;
  height: 100%;
}

/*** Tuile article ***/

.homeTuilesPrincipales{
  margin: 200px auto;
}
#tuileHome:nth-child(even){
  flex-direction: row-reverse;
}
.homeTuilesPrincipales .tuileContainer{
  background: $lightBlueGray;
}
.tuileContainer .imgContainer{
  width: 50%;
}
.tuileContainer .imgContainer img{
  width: 100%;
}
.tuileContainer .textContainer{
  padding: 0;
  margin: 0;
  width: 50%;
  align-self: center;
  position: relative;
  cursor: pointer;
}
.tuileContainer .textContainer .text{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.tuileContainer .textContainer .techno{
  opacity: 0;
  background: $lightBlueGray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  color: $wordsGray;
  font-weight: lighter;
  font-size: 13px;
  transition:opacity 900ms ease-in-out;
}
#tuileHome:hover .techno{
  opacity: 1;
}
.tuileContainer .textContainer .techno .textTechno{
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
height: 100%;
padding: 0;
margin-top: -15px;
  p{
    padding: 20px;
    padding-top: 0;
    padding-bottom: 10px;
  }
}
#bigData .techno .textTechno p{
  padding-bottom: 20px;
}
.tuileContainer .textContainer .text h3{
  margin: 0;
  font-weight: lighter;
  color: $blue;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
}
.tuileContainer .textContainer .text .textPrinc p{
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: $wordsArticle;
}
/*.tuileContainer .textContainer .textTechno{
  margin: 0;
  margin-top: 20px;
  color: $wordsGray;
  font-weight: lighter;
  font-size: 13px;
  text-align: justify;
}
.tuileContainer .textContainer .text .techno p{
  margin: 0;
}*/
#conseil .text .textPrinc p{
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}
#conseil, #adminSys, #dataScience{
  cursor: default;
}
#conseil .techno, #adminSys .techno, #dataScience .techno{
  display: none;
}
.tuileContainer .textContainer .borderBottom2{
  align-self: flex-end;
  width: 75%;
  height: 1px;
  background: linear-gradient(to left, $blue, rgba(0, 0, 0, 0));
}
#tuileHome:nth-child(even) .borderBottom2{
  align-self: flex-start;
  background: linear-gradient(to right, $blue, rgba(0, 0, 0, 0));
}

/*** tuile Big image ***/

.tuileBigImgContainer{
  width: 100%;
  background: $blue;
  margin-top: 200px;
  position: relative;
  z-index: 1;
  img{
    width: 100%;
  }
}
.tuileBigImgContainer .tuileBigImgTitle{
  text-align: center;
  padding: 60px;
  color: $white;
  h2{
    font-size: 2.85rem;
    font-weight: 900;
    margin: 0;
  }
  p{
    font-weight: lighter;
    margin-top: 5px;
    margin-bottom: 0;
  }
}
.tuileBigImgContainer .tuileBigImgText{
  position: absolute;
  top: 40%;
  width: 40%;
  padding: 30px;
  background: $lightBlueGray;
  left: 50%;
  h3{
    margin-top: 0;
  }
  // P{
  //   margin-bottom: 0;
  // }
}

/*** Tuile Gallerie ***/

.galleryContainer{
  margin-bottom: 200px;
  width: 100%;
  background: $darkenLightBlue;
  box-shadow: 0px 10px 20px $gray;
  padding-top: 60px;
  padding-bottom: 60px;
  .galleryTitle{
    text-align: center;
    color: $white;
    font-size: 2rem;
  }
  #gallery{
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;
  }
  .galleryItem{
    width: 12%;
    align-self: center;
    padding: 20px;
    img{
      width: 100%;
    }
  }
}
#logoChanelblanc{
  img{
    width: 60%;
    margin-left: 30%;
  }
}
#logoCAblanc{
  img{
    width: 70%;
    margin-left: 15%;
  }
}
#logoLVblanc{
  img{
    width: 40%;
    margin-left: 20%;
  }
}

/*** Tuile Commentaires ***/

#commentairesContainer{
  margin-bottom: 200px;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
}
.commentaires{
  width: 25%;
  padding: 30px;
  h3{
    text-align: center;
    font-weight: lighter;
    margin: 2px;
  }
  span {
    color: $blue;
  }
  p{
    margin-bottom: 0;
  }
  .borderBottom3{
    width: 100%;
    height: 1px;
    background: linear-gradient(to left, $blue, rgba(255, 255, 255, 0));
  }
}

/******************/
/***  CONTACT   ***/
/******************/

#contact{
  margin-top: 150px;
  margin-bottom: 150px;
  flex-direction: column;
}
.backgroundContact{
  background-image: url("./img/contactUs.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
}
.backgroundContactColor{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(51,51,51, 0.93);
}
.ContactTitle{
  padding: 50px;
  margin-bottom: 0;
  //background: $blue;
  color: $white;
  //text-align: center;
  h2{
    //font-weight: lighter;
    font-size: 3rem;
    margin: 0;
  }
  p{
    font-size: 1.5rem;
    text-align: left;
    margin-right: 30%;
  }
}
.borderBottom4{
  align-self: flex-end;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, $white, rgba(255, 255, 255, 0.05));
}
.contactForms{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contactImg{
  height: 253px;
  width: 33.33%;
}
.contactImg img{
  width: 100%;
}
.contactMail{
  height: 311px;
  display: flex;
  width: 50%;
  //background: $darkenLightBlue;
}
.contactTelephone{
  height: 311px;
  width: 50%;
  align-self: center;
  color: $white;
  background: $darkenLightBlue; //$lightBlueGray
  display: flex;
  flex-direction: row;
}
.contactTelephone p{
  padding: 50px;
  text-align: center;
  align-self: center;
  width: 100%;
}
.telephone{
  font-size: 2rem;
  font-weight: lighter;
  color: $white;
  text-decoration: none;
}
.formulaire{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.contactName, .contactImputMail, .contactMessage, .contactButton{
  background: none;
  padding: 10px;
  border: 1px solid $white;
  color: $white;
}
.formGroup{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.contactName, .contactImputMail{
  width: 50%;
  margin-bottom: 10px;
}
.contactName{
  margin-right: 5px;
}
.contactImputMail{
  margin-left: 5px;
}
.contactMessage, .contactButton{
  width: 100%;
}
.contactMessage::placeholder, .contactName::placeholder, .contactImputMail::placeholder {
  color: $white;
}
.contactButton{
  background: $blue;
  margin-top: 10px;
  color: $white;
  cursor: pointer;
  border: none;
}
.formulaire p{
  color: $white;
  margin-bottom: 0;
  font-weight: lighter;
  font-size: 0.75rem;
}
.formulaire .success,.formulaire .error {
  display: none;
}
/******************/
/***    ABOUT   ***/
/******************/

#about{
  margin-top: 200px;
  margin-bottom: 150px;
  flex-direction: column;
  flex-wrap: wrap;
}
.titleAbout{
  padding: 50px;
  background: $darkenLightBlue;
}
.titleAbout h2{
  text-align: center;
  margin: 0;
  font-size: 2rem;
  font-weight: lighter;
  color: $white;
}
.tableAbout{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.cellAbout{
  width: 50%;
  padding: 30px;
}
.cellAbout h3{
  margin: 0;
  margin-bottom: 20px;
  font-weight: lighter;
  font-size: 1.5rem;
}
.cellLeft{
  background: $lightBlueGray;
  color: $gray;
}
.cellRight{
  background: $blue;
  color: $white;
}
.logoAbout{
  background: $darkenLightBlue;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .itemAbout{
    width: 18%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    img{
      align-self: center;
      width: 100%;
    }
  }
}
#logoEnedis{
  margin-top: -20px;
}

/**********************/
/*** Page Secondary ***/
/**********************/

#errorPage, #credits{
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 250px;
  margin-bottom: 150px;
}
#errorPage {
  text-align: center;
}
#errorPage h2, #credits h2{
  font-size: 6rem;
  font-weight: lighter;
  color: $blue;
}

#credits h2{
  font-size: 2rem;
}
#credits span{
  color: $blue;
}
#credits a{
  text-decoration: none;
  color: $blue;
}
#credits a:hover{
  text-decoration: underline;
}

#connexion{
  margin-top: 240px;
  margin-bottom: 150px;
  .formConnexion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: $lightBlueGray;
    padding: 50px;
    h2{
      text-align: center;
      margin-top: 0;
      margin-bottom: 50px;
    }
    .login, .mdp{
      text-align: center;
      margin-bottom: 15px;
    }
    .connexionBtn{
      text-align: center;
    }
    input{
      padding: 20px 150px;
      text-align: center;
    }
    button{
      background: $blue;
      color: $white;
      border: none;
      padding:  20px 210px;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 1500px){
  .tuileContainer {
    width: 80%;
  }
  #contact{
    margin-top: 50px;
  }
  .backgroundContactColor{
    position: relative;
  }
}
@media all and (max-width: 1405px){
  .TuileAccueil{
    .bannerBackground{
      width: 80%;
      left: calc(20% - 1px);
    }
    .banner{
      h2{
        //text-align: center;
        font-size: 2.3rem;
      }
      p{
        //text-align: center;
        font-size: 1.2rem;
      }
      .borderBottom{
        //width: 63%;
      }
    }
  }
  #developpeur .text .textPrinc p{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #bigData .text .textPrinc p{
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #DevOps .text .textPrinc p{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  #dataScience .text .textPrinc p{
    font-size: 15px;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  #adminSys .text .textPrinc p{
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #conseil .text .textPrinc p{
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

// tablette
@media all and (max-width: 1138px) {
  /*** header ***/
  .headerContainer{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .headerContainer .title{
    width: 30%;
    margin-left: 30px;
    img{
      width: 75px;
    }
  }
  .headerContainer .navigation{
    width: 70%;
    margin-right: 15px;
  }
  .headerContainer .navigationPrincipale ul{
    margin-right: 15px;
  }
  .headerContainer .navigationPrincipale li{
    padding: 0 20px 5px;
  }
  /*** footer ***/
  .footerContainer .title{
    margin-left: 30px;
  }
  .footerContainer .contactFooter{
    width: 15%;
  }
  .footerContainer .navigation{
    margin-right: 30px;
  }
  .footerContainer .navigationPrincipale ul{
    margin-right: 0;
  }
  .footerContainer .navigationPrincipale li{
    padding: 0 10px;
  }
  /*** home ***/
  #buttonToTop{
    img{
      width: 40px;
    }
  }
  .TuileAccueil{
    .bannerBackground{
      width: 85%;
      left: calc(15% - 1px);
    }
    .banner{
      h2{
        margin-left:18%;
      }
      .bannerText{
        margin-left: 18%;
      }
      .borderBottom{
        width: 82%;
      }
    }
  }
  .tuileContainer{
    width: 90%;
  }
  .tuileContainer .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .tuileContainer .textContainer .text{
    padding: 10px;
  }
  .tuileContainer .textContainer .text h3{
    font-size: 1.2rem;
  }
  .tuileContainer .textContainer .text .techno{
    margin-top: 10px;
    font-size: 11px;
  }
  #developpeur .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #bigData .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #DevOps .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #dataScience .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #adminSys .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #conseil .text .textPrinc p{
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .galleryContainer #gallery{
    flex-wrap: wrap;
  }
  .galleryContainer .galleryItem{
    width: 20%;
  }
}

@media all and (max-width: 1030px) {
  .TuileAccueil{
    .banner{
      h2{
        font-size: 2rem;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .TuileAccueil{
    .banner{
      h2{
        font-size: 1.8rem;
      }
    }
  }
}

@media all and (max-width: 810px) {
  .TuileAccueil{
    .banner{
      h2{
        font-size: 1.5rem;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .headerContainer{
    width: 100%;
    position: absolute;
    padding-top: 0;
    top: 0;
  }
  .headerContainer .headerBackground{
    flex-direction: column;
    position: absolute;
    flex-wrap: wrap;
    //background: none;
    border-radius: 0px;
    width: 100%;
  }
  .headerBackground .title{
    width: 90%;
    align-self: center;
    margin: 0 auto;
    img{
      margin-left: calc(50% - 40px);
      width: 80px;
    }
  }
  .headerBackground .navigation{
    width: 100%;
    margin: 0 auto;
  }
  .headerBackground .navigationPrincipale ul{
    justify-content: center;
    margin-right: 0px;
  }
  .headerBackground .navigationPrincipale li{
    padding: 0 5px 5px;
  }
  .headerBackground .borderBottom{
    display: none;
  }
  #buttonToTop{
    padding: 5px;
    img{
      width: 25px;
    }
  }
  .TuileAccueil .bannerBackground{
    width: 100%;
    left: 0;
    border: 0.5px solid #ffffff
  }
  .TuileAccueil .banner h2{
    font-size: 1.5rem;
    margin-left: 0;
    text-align: center;
  }
  .TuileAccueil .banner .bannerText{
    font-size: 1.2rem;
    font-weight: lighter;
    margin-left: 0;
    justify-content: center;
    flex-wrap: wrap;
    p{
      margin: 0;
      padding: 5px;
    }
  }
  .tuileContainer{
    width: 100%;
    flex-direction: column;
  }
  #tuileHome:nth-child(even){
    flex-direction: column;
  }
  .tuileContainer .imgContainer{
    width: 100%;
  }
  .tuileContainer .imgContainer img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .tuileContainer .textContainer{
    width: 100%;
  }
  .tuileContainer .textContainer .text{
    padding: 5px;
  }
  .tuileContainer .textContainer .techno{
    padding: 5px;
  }
  .tuileContainer .textContainer .techno .textTechno {
    margin-top: 0;
  }
  .tuileContainer .textContainer .techno .textTechno p{
    padding: 5px;
    padding-top: 0;
    padding-bottom: 10px;
    margin: 0;
  }
  .tuileBigImgContainer .tuileBigImgText{
    position: relative;
    width: 100%;
    left: 0;
  }
  .galleryContainer #gallery{
    justify-content: center;
    flex-direction: column;
  }
  .galleryContainer .galleryTitle{
    font-size: 1.5rem;
  }
  .galleryContainer .galleryItem{
    width: 80%;
  }
  #logoChanelblanc img{
    margin-left: 20%;
  }
  #logoEDFblanc{
    margin-top: -70px;
  }
  #logoEnedisblanc{
    margin-top: -80px;
  }
  #commentairesContainer{
    flex-direction: column;
  }
  #commentairesContainer .commentaires{
    width: 100%;
  }
  .upFooter, .downFooter{
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  .upFooter{
    padding: 0;
  }
  .footerContainer .title{
    width: 30%;
    margin: 0 auto;
  }
  .footerContainer .title img {
    width: 80px;
    margin-left: calc(50% - 40px);
  }
  .footerContainer .contactFooter{
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
  .footerContainer .navigation{
    margin-right: 0;
    width: 100%;
  }
  .footerContainer .navigationPrincipale ul{
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
  }
  .footerContainer .navigationPrincipale li{
    padding: 10px;
    text-align: center;
  }
  .downFooter{
    text-align: center;
    a{
      padding-bottom: 10px;
    }
  }

  /*** contact ***/
  #contact{
    margin-top: 100px;
  }
  .backgroundContactColor{
    position: relative;
  }
  .ContactTitle{
    padding: 20px;
  }
  .ContactTitle h2{
    font-size: 1.5rem;
  }
  .ContactTitle p{
    font-size: 1rem;
    margin-right: 0;
    //text-align: center;
  }
  .contactForms{
    flex-direction: column;
  }
  .contactImg{
    width: 100%;
    height: auto;
  }
  .contactMail{
    width: 100%;
    height: auto;
  }
  .formGroup{
    flex-direction: column;
    flex-wrap: wrap;
  }
  .formulaire{
    padding: 20px;
    padding-top: 0;
  }
  .contactName, .contactImputMail{
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .contactMessage{
    padding-bottom: 50px;
  }
  .formulaire p{
    font-size: 1rem;
  }
  .contactTelephone{
    width: 100%;
    height: 155px;
    p{
      padding: 20px;
    }
  }

  /*** about ***/
  .tableAbout{
    flex-direction: column;
  }
  .cellAbout{
    width: 100%;
  }
  .logoAbout{
    flex-direction: column;
    .itemAbout{
      width: 60%;
      align-self: center;
    }
  }
  #logoEnedis{
    margin-top: 0px;
  }
}
@media all and (max-width: 375px) {
  .TuileAccueil{
    .banner{
      h2{
        font-size: 1.3rem;
        margin-left:0;
        text-align: center;
      }
      .bannerText{
        font-size: 1rem;
        margin-left: 0;
        justify-content: center;
        flex-wrap: wrap;
        p{
          margin: 0;
          padding: 5px;
        }
      }
      .borderBottom{
        width: 82%;
      }
    }
  }
}
